import { TransUnionManualVerification } from '~/pages/rental-application/screening/components/TransUnionManualVerification';

const ManualVerificationPage = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <TransUnionManualVerification />
    </div>
  );
};

export default ManualVerificationPage;
